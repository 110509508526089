<template>
  <v-card flat class="pa-7">
    <v-card-title>
      <v-text-field
        style="max-width: 400px"
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        clearable
        dense
        hide-details
      ></v-text-field>

      <v-spacer></v-spacer>
      <v-btn
        @click="goToAddEquipe()"
        align="center"
        color="secondary"
        class="white--text"
        small
      >
        Adicionar
      </v-btn>
    </v-card-title>

    <v-divider></v-divider>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :search="search"
        :items="equipes"
        :items-per-page="10"
        :sort-desc="true"
        class="data-tables data-tables__row-click"
        @click:row="goToEquipe"
      >
        <template v-slot:item.responsavel="{ item }">
          {{ item.responsavel ? "Sim" : "Não" }}
        </template>
        <template v-slot:item.status="{ item }">
          {{ item.status | status }}
        </template>
        <!-- <template v-slot:item.actions="{ item }">
          <v-dialog
            v-model="dialogDelete"
            persistent
            transition="dialog-top-transition"
            max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon color="error" v-bind="attrs" v-on="on" small>
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
            <template>
              <v-card>
                <v-toolbar color="red" dark
                  >deseja deletar esse usuario ?</v-toolbar
                >

                <v-card-actions class="justify-start pt-10">
                  <v-btn text @click="notDelUser()">nao</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    class="red"
                    color="white"
                    :loading="loadingDelete"
                    @click="delUser(item.id)"
                    >sim
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </v-dialog>
        </template> -->
      </v-data-table>
    </v-card-text>

    <AddEquipe
      v-if="dialogAdd"
      :dialogAdd.sync="dialogAdd"
      @fetch-equipe="getEquipes"
    />
    <EditEquipe
      v-if="dialogEdit"
      :dialogEdit.sync="dialogEdit"
      :equipe_id="equipe_id"
      @fetch-equipe="getEquipes"
    />
  </v-card>
</template>

<script>
import {
  fetchEquipes,
  //  deleteEquipe
} from "@/api/gestor/cliente_equipe.js";
export default {
  name: "Equipe",

  props: {
    cliente_id: {
      type: [Number, String],
      required: true,
    },
  },

  components: {
    AddEquipe: () => import("./components/AddEquipe.vue"),
    EditEquipe: () => import("./components/EditEquipe.vue"),
  },

  data() {
    return {
      loading: true,
      equipes: [],
      search: "",
      dialogAdd: false,
      dialogEdit: false,
      dialogDelete: false,
      equipe: null,
      equipe_id: null,
      add: false,
      clienteEquipeUserId: null,
      loadingDelete: false,
    };
  },

  computed: {
    headers() {
      return [
        {
          text: "Nome",
          value: "nome",
        },
        {
          text: "Cargo",
          value: "cargo.descricao",
        },
        {
          text: "Responsavel",
          value: "responsavel",
        },

        {
          text: "Status",
          value: "status",
        },

        // {
        //   text: "deletar",
        //   value: "actions",
        // },
      ];
    },
  },

  methods: {
    // delUser(clienteEquipeUserId) {
    //   this.loadingDelete = true;
    //   this.clienteEquipeUserId = clienteEquipeUserId;
    //   deleteEquipe(clienteEquipeUserId)
    //     .then(() => {
    //       this.$toast.success("Equipe Cliente removido");
    //       this.getEquipes();
    //       this.loadingDelete = false;
    //       this.dialogDelete = false;
    //     })
    //     .catch(() => {
    //       this.loadingDelete = false;
    //     });
    // },
    // notDelUser() {
    //   this.loadingDelete = false;
    //   this.clienteEquipeUserId = null;
    //   this.dialogDelete = false;
    // },
    goToEquipe(item) {
      this.equipe_id = item.id;
      this.dialogEdit = true;
    },
    goToAddEquipe() {
      this.equipe = {};
      this.dialogAdd = true;
    },

    getEquipes() {
      this.loading = true;
      fetchEquipes(`?cliente_id=${this.cliente_id}`)
        .then((response) => {
          this.equipes = response;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },

  mounted() {
    this.getEquipes();
  },
};
</script>

<style lang="scss"></style>
