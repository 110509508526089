import api from "../axios_service.js";

export async function fetchEquipes(filtros) {
  const response = await api.get(`/gestor/clientes-equipe${filtros ? filtros : ""}`);
  return response.data.result;
}

// Busca um cliente
export async function fetchEquipe(id) {
  const response = await api.get(`/gestor/clientes-equipe/${id}`);
  return response.data.result;
}

// Atualiza um cliente existente
export function putEquipe(id, equipe) {
  let body = {};
  for (let key in equipe) {
    if (key != "acesso" && key != "cargo") {
      body[key] = equipe[key];
    }
  }
  return api.put(`/gestor/clientes-equipe/${id}`, body);
}

// Cria um cliente novo
export async function postEquipe(equipe) {
  const response = await api.post("/gestor/clientes-equipe/add", equipe);
  return response;
}

export async function uploadAvatar(id, fd) {
  const response = await api.post(`/gestor/clientes-equipe/${id}/avatar`, fd);
  return response;
}

// export async function deleteEquipe(id) {
//   return await api.put(`/gestor/clientes-equipe-delete/${id}`);
// }
